<template>
  <div class="container" v-loading="loading">
    <div v-if="qaList.length">
      <div class="title">问卷列表</div>
      <div class="list">
        <el-card class="atom" v-for="q in qaList" :key="q.id">
          <div class="atom-card" @click="open(q.url)">
            <div class="atom-card-left">
              <img
                v-if="q.survey_name && q.survey_name == 'dx'"
                :src="require('../../assets/images/questionnaire/dx.png')"
              />
              <img v-else :src="require('../../assets/images/questionnaire/nipo.png')" />
              <div>{{ q.title }}</div>
            </div>
            <i class="el-icon-arrow-right" />
          </div>
        </el-card>
      </div>
    </div>
    <div class="result-null" v-if="!loading && !qaList.length">
      <img :src="require('../../assets/images/questionnaire/result_null.png')" />
      <div>暂无问卷</div>
    </div>
  </div>
</template>

<script>
import { service } from '@/utils/axios'
export default {
  name: 'QuestionnaireList',
  data() {
    return {
      qaList: [],
      loading: true,
    }
  },
  methods: {
    async init() {
      const res = await service.post('/app/get_qa')
      if (res.data.ret_code === 0) {
        this.loading = false
        this.qaList = res.data.data
      }
    },
    open(url) {
      location.href = url
    },
    reload() {
      document.visibilityState === 'visible' && this.$route.path === '/questionnaire-list' && this.init()
    },
  },
  created() {
    this.init()
    window.addEventListener('visibilitychange', this.reload)
  },
  beforeDestroy() {
    window.removeEventListener('visibilitychange', this.reload)
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  max-width: 1198px;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  .title {
    margin: 50px 0 14px 5px;
    font-size: 22px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .atom {
      width: 548px;
      margin-bottom: 18px;
      cursor: pointer;
      &:hover {
        color: sandybrown;
      }
      &-card {
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-left {
          display: flex;
          align-items: center;
          img {
            display: block;
            margin-right: 19px;
          }
        }
      }
    }
  }
  .result-null {
    margin: 50px auto;
    text-align: center;
    color: #999;
    font-size: 18px;
    img {
      margin: 0 auto 20px;
      display: block;
    }
  }
}
</style>
